import React from 'react';

import Head from '../../components/Head';
import TodoSubList from '../../components/TodoSubList';

import '../../style/_common.scss';

class TodoPage extends React.Component {
  render() {
    const title = 'How to Establish Relationships With Labs and Other Vendors';
    const desc = `Use these resources to learn more about how to establish sources of materials and services you'll need to run your direct primary care practice.`;
    return (
      <TodoSubList title={title} desc={desc} id="vendors">
        <Head title={title} description={desc} url="/todo/vendors/" image="/og_image.jpg" />
      </TodoSubList>
    );
  }
}

export default TodoPage;
